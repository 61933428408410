/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';
// Fragments
import {
  StreamDestinationFields,
  DestinationFields,
  HostFields,
} from './fragments';

export const CHANGE_STREAM_MODE = gql`
  mutation ChangeStreamMode($id: ID!, $mode: StreamModeEnum!) {
    changeStreamMode(id: $id, mode: $mode) {
      id
      slug
      mode
      rtmpIngestURL
      rtmpIngestKey
      rtmpPlaybackUrl
    }
  }
`;

export const LOG_CLIENT_JOIN = gql`
  mutation Log($input: LogInput!) {
    log(input: $input)
  }
`;

// Multi-streaming
export const ENABLE_STREAM_DESTINATION = gql`
  mutation EnableStreamDestination($destinationId: ID!, $streamId: String!) {
    enableStreamDestination(
      destinationId: $destinationId
      streamId: $streamId
    ) {
      ...StreamDestinationFields
    }
  }
  ${StreamDestinationFields}
`;

export const DISABLE_STREAM_DESTINATION = gql`
  mutation DisableStreamDestination($destinationId: ID!, $streamId: String!) {
    disableStreamDestination(
      destinationId: $destinationId
      streamId: $streamId
    ) {
      ...StreamDestinationFields
    }
  }
  ${StreamDestinationFields}
`;

export const REMOVE_DESTINATION = gql`
  mutation RemoveDestination($destinationId: ID!) {
    removeDestination(destinationId: $destinationId) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const CREATE_FB_PROFILE_DESTINATION = gql`
  mutation CreateFBProfileDestination($accessToken: String!) {
    createFBProfileDestination(accessToken: $accessToken) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const CREATE_FB_PAGE_DESTINATION = gql`
  mutation CreateFBPageDestination($accessToken: String!, $pageId: String!) {
    createFBPageDestination(accessToken: $accessToken, pageId: $pageId) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const CREATE_YT_DESTINATION = gql`
  mutation CreateYTDestination($code: String!) {
    createYTDestination(code: $code) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const CHECK_DESTINATIONS_AUTH = gql`
  mutation CheckDestinationsAuth($destinationIds: [ID!]!) {
    checkDestinationsAuth(destinationIds: $destinationIds) {
      isValid
      destinationId
    }
  }
`;

export const AUTH_FB_PROFILE_DESTINATION = gql`
  mutation AuthFBProfileDestination(
    $accessToken: String!
    $destinationId: String!
  ) {
    authFBProfileDestination(
      accessToken: $accessToken
      destinationId: $destinationId
    ) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const AUTH_FB_PAGE_DESTINATION = gql`
  mutation AuthFBPageDestination(
    $accessToken: String!
    $destinationId: String!
  ) {
    authFBPageDestination(
      accessToken: $accessToken
      destinationId: $destinationId
    ) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const AUTH_YT_DESTINATION = gql`
  mutation AuthYTDestination($code: String!, $destinationId: String!) {
    authYTDestination(code: $code, destinationId: $destinationId) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const CREATE_RTMP_DESTINATION = gql`
  mutation CreateRTMPDestination(
    $name: String!
    $rtmpUrl: String!
    $rtmpKey: String!
  ) {
    createRTMPDestination(name: $name, rtmpUrl: $rtmpUrl, rtmpKey: $rtmpKey) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

export const EDIT_RTMP_DESTINATION = gql`
  mutation EditRTMPDestination(
    $destinationId: ID!
    $storeId: String!
    $name: String!
    $rtmpUrl: String!
    $rtmpKey: String!
  ) {
    editRTMPDestination(
      destinationId: $destinationId
      storeId: $storeId
      name: $name
      rtmpUrl: $rtmpUrl
      rtmpKey: $rtmpKey
    ) {
      ...DestinationFields
    }
  }
  ${DestinationFields}
`;

// Co-Hosting
export const CREATE_COHOST_TOKEN = gql`
  mutation CreateCohostToken($input: CreateCohostTokenInput!) {
    createCohostToken(input: $input) {
      accessToken
      streamSlug
    }
  }
`;

export const ACCEPT_COHOST = gql`
  mutation AcceptCohost($streamId: ID!, $id: String!) {
    acceptCohost(streamId: $streamId, id: $id) {
      ...HostFields
    }
  }
  ${HostFields}
`;

export const STOP_COHOST = gql`
  mutation StopCohost($streamId: ID!, $id: String!) {
    stopCohost(streamId: $streamId, id: $id) {
      ...HostFields
    }
  }
  ${HostFields}
`;

export const COHOST_JOIN = gql`
  mutation CohostJoin($uid: String!) {
    cohostJoin(uid: $uid) {
      ...HostFields
    }
  }
  ${HostFields}
`;

export const COHOST_READY_REQUEST = gql`
  mutation CohostReadyRequest {
    cohostReadyRequest {
      ...HostFields
    }
  }
  ${HostFields}
`;

export const COHOST_PAUSE = gql`
  mutation CohostPause {
    cohostPause {
      ...HostFields
    }
  }
  ${HostFields}
`;

export const COHOST_LEAVE = gql`
  mutation CohostLeave {
    cohostLeave {
      ...HostFields
    }
  }
  ${HostFields}
`;

// Analytics
export const PLAY_BUTTON_CLICKED = gql`
  mutation PlayButtonClicked($streamId: ID!) {
    playButtonClicked(streamId: $streamId)
  }
`;
